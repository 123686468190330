import React from 'react';
import { graphql, PageProps } from 'gatsby';
import { getFlowButtonHandler } from '../components/Dialog';
import Layout from '../components/Layout';
import Seo from '../components/SEO/seo';
import FlowPoster from '../components/blog/FlowPoster';
import heroImage from '../assets/images/snelstart/hero.png';
import thisisflow from '../assets/images/snelstart/thisisflow.png';
import howflowworks from '../assets/images/snelstart/howflowworks.png';
import SSTAndFlow from '../assets/images/snelstart/sst+flow.png';
import iconGraph from '../assets/images/snelstart/icon-graph.png';
import iconBudget from '../assets/images/snelstart/icon-budget.png';
import iconMoneyBag from '../assets/images/snelstart/icon-moneybag.png';
import listIcon1 from '../assets/images/snelstart/list-icon-1.png';
import listIcon2 from '../assets/images/snelstart/list-icon-2.png';
import listIcon3 from '../assets/images/snelstart/list-icon-3.png';
import smiley from '../assets/images/meesman/smiley.svg';
import like from '../assets/images/meesman/like.svg';
import i18next from 'i18next';


const SnelStart = (page: PageProps) => {
  return (
    <Layout>
      <Seo page={page} title="SnelStart" />
      <main className="page-snelstart">
        <section className="section-1">
          <div className="container">
            <div className="section-1__content">
              <div className="section-1__block u-spacing-large">
                <h2 className="section-1__title u-title-secondary">
                  Werk samen met je klant naar gezonde financiën
                </h2>
                <div className="section-1__text u-text-primary">
                  <span className="u-color-violet-alt">Flow+</span><span className="section-1__strong">SnelStart</span> De inkomsten volledig automatisch verdeeld over verschillende rekeningen, en inzichtelijk met de Flow integratie in SnelStart.
                </div>
                <button className="btn btn--primary" onClick={() => getFlowButtonHandler()}>
                  Download de app
                </button>
              </div>
              <img
                src={heroImage}
                width="550"
                alt="img"
                className="section-1__img"
              />
            </div>
          </div>
        </section>

        <section className="section-2">
          <div className="container">
            <h2 className="section-2__title">
              Samenwerken met je klant naar gezonde financiën moet de norm zijn.
            </h2>
            <div className="section-2__content">
              <div className="section-2__block">
                <img src={smiley} alt="smiley" className="section-2__img" />
                <h3>Herken je dit?</h3>
                <ul className="list-unstyled">
                  <li>Je bent er alleen ‘voor de bonnetjes‘</li>
                  <li>Je klanten hebben geen idee hoe ze er financieel voor staan</li>
                  <li>Je klanten zetten niets apart voor de inkomstenbelasting en btw</li>
                  <li>Er is geen inzicht in hoeveel ze te besteden hebben (nieuwe auto, bedrijfspand)</li>
                </ul>
              </div>
              <div className="section-2__block">
                <img src={like} alt="like" className="section-2__img" />
                <h3>En wil je dit?</h3>
                <ul className="list-unstyled">
                  <li>Geef je klant inzicht en controle over de financiën</li>
                  <li>Maak het leven van je ondernemers beter: in één oogopslag weten wat ze  kunnen besteden</li>
                  <li>Wees de financiële professional waarbij boekhouding geen noodzakelijk kwaad is</li>
                </ul>
              </div>
            </div>

          </div>
        </section>

        <section className="section-2">
          <div className="container">
            <h2 className="section-2__title">Dit is Flow</h2>

            <div className="">
              <p className="section-4__paragraph">Flow is een PSD2 betaaldienstverlener. De Flow app verdeelt inkomsten automatisch over verschillende rekeningen, buffers en budgetten. Dit doen we volgens het principe van pre-boekhouden: het geld wordt verdeeld over verschillende rekeningen (potjes), zodra het binnenkomt.</p>

              <p className="section-4__paragraph">Hierdoor heeft de ondernemer direct inzicht in hoe hij ervoor staat door te kijken naar de bankrekeningen. Dit voorkomt vervelende verrassingen, zoals het niet hebben klaarstaan van het verschuldigde IB of btw-bedrag.</p>
            </div>

            <div>
              <img src={thisisflow} alt="Dit is Flow" className="snelstart__img" style={{marginBottom: 0}} />
            </div>
            
            <div>
              <p className="section-4__paragraph">Hoe werkt dit? Bijvoorbeeld: Een factuur wordt betaald. Flow zet direct 21% apart op de btw-rekening, maakt 10% over naar de kostenrekening en zet automatisch de inkomstenbelasting apart. Zo kun je proactief controle krijgen over de geldstromen en is er altijd voldoende geld voor belastingen. Richting de lange termijn kun je samen met je ondernemer werken aan het opbouwen van buffers en het optimaliseren van winst en kosten.</p>
            </div>
          </div>
        </section>

        <section className="section-2">
          <div className="container">
            <h2 className="section-2__title">Zo werkt de meekijkfunctie in SnelStart</h2>
            <img src={SSTAndFlow} alt="SnelStart + Flow logo's" className="" width="240" />

            <p className="section-4__paragraph">Met de Flow integratie in SnelStart werk je samen met je klant. Gebruik de kant-en-klare templates, of bouw simpel je eigen templates voor je klanten. Met jouw op maat gemaakte Flows werk je samen met je klant naar een financieel gezonde onderneming.</p>

            <div className="">
              <img src={howflowworks} alt="Zo werkt de meekijkfunctie in SnelStart" className="snelstart__img" />
            </div>

            <div className="section-5__content">
              <div className="section-5__card">
                <img src={iconGraph} />
                Btw en inkomstenbelasting automatisch apart op de daarvoor bestemde rekeningen
              </div>
              <div className="section-5__card">
                <img src={iconBudget} />
                Automatisch budgetteren voor verschillende uitgaven
              </div>
              <div className="section-5__card">
                <img src={iconMoneyBag} />
                Eenvoudig buffers opbouwen
              </div>
            </div>
          </div>
        </section>

        <section className="section-2">
          <div className="container">
            <h2 className="section-2__title">Gebruik de meekijkfunctie in 3 simpele stappen voor boekhouders</h2>

              <h3 className="numbered-list">
                <span className="number">1</span> 
                <img src={listIcon1} />
                Activeer de Flow module in SnelStart
              </h3>
              <p className="section-4__paragraph">Ga naar SnelStart Flow. Deze vind je in het dashboard van SnelStart, je klikt dan op het administratieoverzicht zodat je direct kunt zien welke ondernemers al gekoppeld zijn.</p>
              
              <h3 className="numbered-list">
                <span className="number">2</span> 
                <img src={listIcon2} />
                Nodig je klanten uit voor Flow
              </h3>
              <p className="section-4__paragraph">Bespreek het gemak van Flow voor de ondernemer. Stuur per mail de Flow uitnodiging naar de ondernemer die je wilt koppelen.</p>
              
              <h3 className="numbered-list">
                <span className="number">3</span> 
                <img src={listIcon3} />
                Automatiseer de geldstromen van je klant
              </h3>
              <p className="section-4__paragraph">Laat de meekijkfunctie activeren en activeer samen de passende flows over de verschillende rekeningen. In de app van Flow kijk je eenvoudig mee met de geldzaken van je klant en til je de onderneming naar een financieel nieuw niveau. En dit is uniek.</p>
          </div>
        </section>

        <section>
          <div style={{textAlign: 'center', marginBottom: '6rem'}}>
            <a href={`${i18next.language == 'nl' ? '/nl' : ''}/flow-snelstart-partnership`} className="u-link-secondary" style={{fontSize: '2rem', fontWeight: 300}}>Lees hoe we omgaan met jouw gegevens in deze SnelStart samenwerking</a>
          </div>
        </section>

        <section>
          <div className="container">
            <FlowPoster extended />
          </div>
        </section>
      </main>
    </Layout>
  );
};

export default SnelStart;

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
