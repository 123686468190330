import React from 'react';
import { getFlowButtonHandler } from '../Dialog';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import meesmanPosterEN from '../../assets/images/webinar/flow-poster-image-meesman.png';
import meesmanPosterNL from '../../assets/images/webinar/flow-poster-image-meesman-nl.png';
import flowPosterEN from '../../assets/images/webinar/flow-poster-image-default.png';
import flowPosterNL from '../../assets/images/webinar/flow-poster-image-default-nl.png';
import cx from 'classnames';

interface FlowPosterProps {
  extended?: boolean;
  meesman?: boolean;
  className?: string;
}

const FlowPoster = ({ extended, meesman, className }: FlowPosterProps) => {
  const { t, i18n } = useTranslation('blog');
  return (
    <article
      className={cx('poster', {
        'poster--extended': extended || meesman,
        [`${className}`]: className,
      })}
    >
      <div className="poster__col">
        <div className="poster__title u-color-primary">
          {t('Poster.title', 'Sign up Your Flow')}
        </div>
        <p className="poster__text u-color-primary">
          {t(
            `Poster.${extended ? 'text' : 'meesmanText'}`,
            `Automate saving, budgeting and investing so you can feel relaxed and
        confident about your money.`
          )}
        </p>
        <button onClick={() => getFlowButtonHandler()} className="btn btn--primary">
          {t('Poster.buttonTitle', 'Get the app')}
        </button>
      </div>
      {(extended || meesman) && (
        <div className="poster__col">
          <img
            src={
              extended
                ? i18n.language === 'nl'
                  ? flowPosterNL
                  : flowPosterEN
                : i18n.language === 'nl'
                ? meesmanPosterNL
                : meesmanPosterEN
            }
            width="476"
            alt="poster"
            className="poster__img"
          />
        </div>
      )}
    </article>
  );
};

export default FlowPoster;
